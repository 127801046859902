export const SIGN_OUT_USER_SUCCESS = "SIGN_OUT_USER_SUCCESS"
export const SIGN_OUT_USER_ERROR = "SIGN_OUT_USER_ERROR"

export const SIGN_IN_USER_SUCCESS = "SIGN_IN_USER_SUCCESS"
export const SIGN_IN_USER_ERROR = "SIGN_IN_USER_ERROR"

export const ADD_ACTIVITY_SUCCESS ="ADD_ACTIVITY_SUCCESS"
export const UPDATE_ACTIVITY_SUCCESS ="UPDATE_ACTIVITY_SUCCESS"
export const DELETE_ACTIVITY_SUCCESS ="DELETE_ACTIVITY_SUCCESS"


export const ADD_STAFF_SUCCESS ="ADD_STAFF_SUCCESS"
export const UPDATE_STAFF_SUCCESS ="UPDATE_STAFF_SUCCESS"
export const DELETE_STAFF_SUCCESS ="DELETE_STAFF_SUCCESS"

export const GET_ALL_STAFF_SUCCESS ="GET_ALL_STAFF_SUCCESS"
export const GET_ALL_ACTIVITY_SUCCESS = "GET_ALL_ACTIVITY_SUCCESS"

export const HAS_ERROR="HAS_ERROR"
