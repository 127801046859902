import React from 'react';
import ReactDom from 'react-dom';
import HeaderBar from '../../../components/header'
import FooterBar from '../../../components/footer'
import Sidebar from '../../../components/sidebar'
import {localStyle} from './style'
import { connect } from 'react-redux';
import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import {addActivity,updateActivity} from '../../../actions/posts'

const config = {
  apiKey: "AIzaSyDKueic61qsmc8rFFRm-gzjByb9sv_GRAc",
  databaseURL: "https://meghwal-samaj-social-app-77898.firebaseio.com",
  storageBucket: "meghwal-samaj-social-app-77898.appspot.com"
};
firebase.initializeApp(config);
const closeIcon = require('../../../images/close.png')

class AddActivity extends React.Component {
	constructor(props){
		super(props);
		this.state={
			activityName: '',
			activityDescription: '',
			image: ''
		}
	}	

	componentDidMount(){
		var values = this.props.location.state
		if( values && values.activity && values.isEditActivity){
			this.setState({
				isEditActivity: values.isEditActivity,
				activityName: values.activity.title,
				activityDescription: values.activity.description,
				image: values.activity.image,
				id: values.activity.id
			})
		}
	}

	handleAddActivity(){
		var data = {
			activityName: this.state.activityName,
			activityDescription: this.state.activityDescription,
			image: this.state.image,
			token: this.props.auth_response.token,
			id: this.state.id
		}

		if(data.activityName && data.activityDescription && data.image){
			this.state.isEditActivity ? this.props.updateActivity(data) : this.props.addActivity(data)
		}else{
			alert('Please enter all details')
		}
	}

	componentDidUpdate(prevProps,prevState){
		if(this.props.activity_add_res != prevProps.activity_add_res){
			if(this.props.activity_add_res.statusCode ==200){
				alert(this.props.activity_add_res.message)
				this.props.history.push('/myschool/dashboard')
			}
		}

		if(this.props.activity_update_res != prevProps.activity_update_res){
			if(this.props.activity_update_res.statusCode ==200){
				alert(this.props.activity_update_res.message)
				this.props.history.push('/myschool/all-activity')
			}
		}
	}

	renderAddActivity(){
		return(

        <div class="row">
		        <div class="col-sm-3"></div>
		        <div class="col-sm-6" style={localStyle.loginContainer}>
		           
		    <h4 style={{textAlign: 'center'}}>{this.state.isEditActivity ? 'Edit Activity' : 'Add Activity'}</h4>
		    <div class="form-group">
		      <label htmlFor="activity">Activity Name:</label>
		      <input 
		      	type="text" 
		      	class="form-control"
		      	value={this.state.activityName} 
		      	id="activity" 
		      	placeholder="Enter activity name" 
		      	name="activity"
		      	onChange = {(e) => {this.setState({activityName: e.target.value})}}
		      	/>
		    </div>

		    <div class="form-group">
		      <label htmlFor="activity">Activity Description:</label>
		      <textarea class="form-control" value={this.state.activityDescription}  rows="4" cols="50" onChange = {(e) => {this.setState({activityDescription: e.target.value})}}>
		      </textarea>
		    </div>


		    <div class="form-group">
		      <label htmlFor="image">Add Image:</label><br/>
		      {
						this.state.image ? 
						<div>
							<div>
								<img src={closeIcon}
								onClick={()=>{this.setState({image: ''})}}
								style={{height: 10, width: 10}}
								/>
							</div>

							<div style={{marginTop: 15}}>
								<img src={this.state.image}
									style={{height: 200, width: 200}}
								/>
							</div>
						</div>
					:
					 <FileUploader
            accept="image/*"
            name="avatar"
            randomizeFilename
            storageRef={firebase.storage().ref("images")}
            onUploadStart={this.handleUploadStart}
            onUploadError={this.handleUploadError}
            onUploadSuccess={this.handleUploadSuccess}
            onProgress={this.handleProgress}
          />
					}
		    </div>
		      <div style={{textAlign: 'center', marginTop: 20}}>
		        <button onClick={()=>{this.handleAddActivity()}}  style={localStyle.loginButton} >{this.state.isEditActivity ? 'Edit Activity' : 'Add Activity'}</button></div>
					</div>
				</div>
		)
	}

	handleChangeUsername = event =>
    this.setState({ username: event.target.value });
	  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
	  handleProgress = progress => this.setState({ progress });
	  handleUploadError = error => {
	    this.setState({ isUploading: false });
	    console.error(error);
	  };
  handleUploadSuccess = filename => {
    this.setState({ avatar: filename, progress: 100, isUploading: false });
    firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then(url => this.setState({ image: url }));
  };

	render(){
		return(
			<div>
				<HeaderBar props={this.props}/>
				<div class="row"  style={{justifyContent: 'center'}}>
					<Sidebar />
					<div class="col-10">
						{
							this.renderAddActivity()
						}
					</div>
				</div>
				<FooterBar />
			</div>
		)
	}
}

const mapStateToProps = (state) => {		
  return {
  	auth_response: state.users.auth_response,
  	activity_add_res :state.posts.activity_add_res,
  	activity_update_res: state.posts.activity_update_res

  }
}
export default connect(mapStateToProps, {addActivity,updateActivity})(AddActivity);