import * as actionTypes from './actionTypes'
import axios from 'axios'
import {APP_CONSTANTS} from '../app_constants'

export function signInUserSuccess(response){
    return {
        type: actionTypes.SIGN_IN_USER_SUCCESS,
        payload: {
            response,
        }
    }
}

export function signInUserError(response){
    return {
        type: actionTypes.SIGN_IN_USER_ERROR,
        payload: {
            response,
        }
    }
}

export function signInUser(data) {
  return async function (dispatch) {
    axios.post(`${APP_CONSTANTS.url}/api/v1/login`,data).then((response) => {
      dispatch(signInUserSuccess(response.data))
    })
    .catch(error => {
        alert(error)
      dispatch(signInUserError(error))
    })
  };
}

export function userLogOutSuccess(response){
    return {
        type: actionTypes.SIGN_OUT_USER_SUCCESS,
        payload: {
            response,
        }
    }
}

export function logout(data) {
  return async function (dispatch) {
    dispatch(userLogOutSuccess(true))
  };
}
