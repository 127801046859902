import * as actionTypes from './actionTypes'
import axios from 'axios'
import {APP_CONSTANTS} from '../app_constants'

export function addActivitySuccess(response){
    return {
        type: actionTypes.ADD_ACTIVITY_SUCCESS,
        payload: {
            response,
        }
    }
}

export function handleError(response){
    return {
        type: actionTypes.HAS_ERROR,
        payload: {
            response,
        }
    }
}

export function addActivity(data) {
    let header = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': data.token 
      }
    }
  return async function (dispatch) {
    axios.post(`${APP_CONSTANTS.url}/api/v1/posts`,data,header).then((response) => {
      dispatch(addActivitySuccess(response.data))
    })
    .catch(error => {
        alert(error)
      dispatch(handleError(error))
    })
  };
}


export function updateActivitySuccess(response){
    return {
        type: actionTypes.UPDATE_ACTIVITY_SUCCESS,
        payload: {
            response,
        }
    }
}

export function updateActivity(data) {
  let header = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': data.token 
      }
    }

  return async function (dispatch) {
    axios.put(`${APP_CONSTANTS.url}/api/v1/posts/`+data.id,data, header).then((response) => {
      dispatch(updateActivitySuccess(response.data))
    })
    .catch(error => {
        alert(error)
      dispatch(handleError(error))
    })
  };
}

export function deleteActivitySuccess(response){
    return {
        type: actionTypes.DELETE_ACTIVITY_SUCCESS,
        payload: {
            response,
        }
    }
}

export function deleteActivity(data) {
  let header = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': data.token 
      }
    }
    
  return async function (dispatch) {
    axios.delete(`${APP_CONSTANTS.url}/api/v1/posts/`+data.id, header).then((response) => {
      dispatch(deleteActivitySuccess(response.data))
    })
    .catch(error => {
        alert(error)
      dispatch(handleError(error))
    })
  };
}

export function getAllActivitySuccess(response){
    return {
        type: actionTypes.GET_ALL_ACTIVITY_SUCCESS,
        payload: {
            response,
        }
    }
}

export function getAllActivity(data) {
    
  return async function (dispatch) {
    axios.get(`${APP_CONSTANTS.url}/api/v1/posts`).then((response) => {
      dispatch(getAllActivitySuccess(response.data))
    })
    .catch(error => {
        alert(error)
      dispatch(handleError(error))
    })
  };
}
