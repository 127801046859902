export const localStyle = {
	loginButton: {
		height: 40,
		width: '100%',
		backgroundColor: '#4aa851',
		borderColor: '#4aa851',
		color: '#fff',
		borderRadius: 10,
		fontWeight: 'bold'
	},
	loginContainer: {
		backgroundColor: '#fff',
		padding: 30, 
		borderRadius: 20
	}
}