import React from 'react';
import HeaderBar from '../../components/header'
import FooterBar from '../../components/footer'
import { connect } from 'react-redux';
import { getAllStaff } from '../../actions/staff'

class Staff extends React.Component {
	constructor(props) {
		super(props);
		this.state = { width: 0, height: 0 };
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentDidMount() {
		this.props.getAllStaff()
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	}

	render() {
		return (
			<div>
				<HeaderBar
					width={this.state.width}
					backgroundColor={'black'} />
				<div class="row" style={{ marginTop: 15 }}>
					<div class="col-sm-1"></div>
					<div class="col-sm-10">
						<div style={{ color: 'black' }}>

							<h4 style={{ textAlign: 'center', marginTop: 10, marginBottom: 20 }}> <b> Staff List </b> </h4>
							<div class="container">
								<div class="row">
									<div class="col-sm-1"></div>
									<div class="col-sm-10">
										<table class="table table-bordered table-hover">
											<tbody>
												<tr>
												</tr>
											</tbody>
											<thead class="thead-dark">
												<tr><th>Post</th>
													<th>Name</th>
													<th>Education</th>
													<th>Experience</th>
													<th style={{ textAlign: 'center' }}>Image</th>
												</tr>
											</thead>

											<tbody>
												{
													this.props.staff_list && this.props.staff_list.map((item) => {
														return (
															<tr>
																<td>{item.post}</td>
																<td>{item.name}</td>
																<td>{item.education}</td>
																<td>{item.experience}</td>

																<td>
																	<div style={{ textAlign: 'center' }}>
																		<img src={item.image ? item.image : require('../../images/noimage.jpg')} class="img-circle" height="150" />
																	</div>
																</td>
															</tr>
														)
													})
												}
											</tbody>
										</table>
									</div>
									<div class="col-sm-1"></div>
								</div>
							</div>

							<div style={{ marginTop: 30 }} class="container">
								<div class="row">
									<div class="col-sm-4">
										<img class="img-fluid" src={require('../../images/swach.jpg')} />
									</div>
									<div class="col-sm-4">
										<img class="img-fluid" src={require('../../images/ssa.gif')} />
									</div>
									<div class="col-sm-4">
										<img class="img-fluid" src={require('../../images/digital.png')} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-sm-1"></div>
				<FooterBar />
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		staff_list: state.staff.staff_list,
	}
}
export default connect(mapStateToProps, { getAllStaff })(Staff);