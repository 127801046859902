import React from 'react';
import ReactDom from 'react-dom';
import HeaderBar from '../../../components/header'
import FooterBar from '../../../components/footer'
import Sidebar from '../../../components/sidebar'
import {localStyle} from '../AddActivity//style'
import { connect } from 'react-redux';
import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import {getAllStaff, deleteStaff} from '../../../actions/staff'

const closeIcon = require('../../../images/close.png')

const image = "https://firebasestorage.googleapis.com/v0/b/meghwal-samaj-social-app-77898.appspot.com/o/images%2Fea9c1322-feac-4a9a-952f-8bc66ab95b18.svg?alt=media&token=81c291d2-b8b5-493d-83af-0d39a46a4363"
class ViewAllStaff extends React.Component {
	constructor(props){
		super(props);
		this.state={
			staff: [
				
			] 
		}
	}	

	componentDidMount(){
		this.props.getAllStaff({token: this.props.auth_response.token})
	}

	componentDidUpdate(prevProps,prevState){
		if(this.props.staff_delete_res != prevProps.staff_delete_res){
			if(this.props.staff_delete_res.statusCode ==200){
				this.props.getAllStaff({token: this.props.auth_response.token})
				alert(this.props.staff_delete_res.message)
			}
		}
	}

	handleDeleteStaff(id){
		var data = {
			id: id,
			token: this.props.auth_response.token
		}
		this.props.deleteStaff(data)
	}

	handleEditStaff(item){
		this.props.history.push('/myschool/add-staff', {staff: item, isEdit: true})
	}

	renderStaff(){
		return(
        <div class="row">
	        <div class="col-sm-1"></div>
	        <div class="col-sm-10" style={localStyle.loginContainer}>
	        	<table class="table table-hover">
					    <thead>
					      <tr>
					        <th>Name</th>
					        <th>Post</th>
					        <th>Education</th>
					        <th>Experience</th>
					        <th>Image</th>
					        <th colSpan="2">Action</th>

					      </tr>
					    </thead>
					    <tbody>
					    {
					    	this.props.staff_list && this.props.staff_list.map((item)=>{
					    		return(
					    			<tr>
							        <td>{item.name}</td>
							        <td>{item.post}</td>
							        <td>{item.education}</td>
							        <td>{item.experience}</td>
							        <td><img src={item.image} style={{height: 100, width: 100}} /></td>
							      	<td><a href="" onClick={()=>{this.handleEditStaff(item)}}>Edit</a></td>
							      	<td><a href="" onClick={()=>{this.handleDeleteStaff(item.id)}}>Delete</a></td>

							      </tr>
					    		)
					    	})
					    }
					      
					    </tbody>
					  </table>
					</div>
					<div class="col-sm-1"></div>
			</div>
		)
	}

	render(){
		return(
			<div>
				<HeaderBar props={this.props}/>
				<div class="row">
					<Sidebar />
					<div class="col-10">
						{
							 this.renderStaff()		
						}
					</div>
				</div>
				<FooterBar />
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
  	auth_response: state.users.auth_response,
  	staff_list: state.staff.staff_list,
  	staff_delete_res: state.staff.staff_delete_res
  }
}
export default connect(mapStateToProps, {getAllStaff, deleteStaff})(ViewAllStaff);