import * as actionTypes from '../actions/actionTypes'
const initialState = {
	activity_add_res: {},
	activity_update_res: {},
	activity_delete_res: {},
	activity_list: []
}

const posts = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.ADD_ACTIVITY_SUCCESS:
			return { ...state, activity_add_res: action.payload.response };

		case actionTypes.UPDATE_ACTIVITY_SUCCESS:
			return { ...state, activity_update_res: action.payload.response };
			
		case actionTypes.DELETE_ACTIVITY_SUCCESS:
			return { ...state, activity_delete_res: action.payload.response };

		case actionTypes.GET_ALL_ACTIVITY_SUCCESS:
			return { ...state, activity_list: action.payload.response.activity_list };
			
		default:
			return state
	}
}

export default posts;