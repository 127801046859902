export const localStyle = {
	normalTab: {
		marginTop: 15, 
		fontWeight: 'bold'
	},
	activeTab: {
		marginTop: 15, 
		fontWeight: 'bold',
		padding: 5,
		backgroundColor: '#565656'
	}
}