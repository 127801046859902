import React from 'react';
import ReactDom from 'react-dom';
import HeaderBar from '../../../components/header'
import FooterBar from '../../../components/footer'
import Sidebar from '../../../components/sidebar'
import {localStyle} from '../AddActivity//style'
import { connect } from 'react-redux';
import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import {getAllActivity,deleteActivity} from '../../../actions/posts'

const closeIcon = require('../../../images/close.png')

class ViewAllActivity extends React.Component {
	constructor(props){
		super(props);
		this.state={
			activity: [
			]
		}
	}	

	componentDidMount(){
		this.props.getAllActivity({token: this.props.auth_response.token})
	}

	deleteActivity(id){
		var data = {
			id: id,
			token: this.props.auth_response.token
		}
		this.props.deleteActivity(data)
	}

	componentDidUpdate(prevProps,prevState){
		if(this.props.activity_delete_res != prevProps.activity_delete_res){
			if(this.props.activity_delete_res.statusCode ==200){
				this.props.getAllActivity({token: this.props.auth_response.token})
				alert(this.props.activity_delete_res.message)
			}
		}
	}

	renderActivity(){
		return(
        <div class="row">
	        <div class="col-sm-1"></div>
	        <div class="col-sm-10" style={localStyle.loginContainer}>
	        	<table class="table table-hover responsive">
					    <thead>
					      <tr>
					        <th>Activity Name</th>
					        <th>Description</th>
					        <th>Image</th>
					        <th colSpan="2">Action</th>

					      </tr>
					    </thead>
					    <tbody>
					    {
					    	this.props.activity_list && this.props.activity_list.map((item)=>{
					    		return(
					    			<tr>
							        <td>{item.title}</td>
							        <td>{item.description}</td>
							        <td><img src={item.image} style={{height: 100, width: 100}} /></td>
							      	<td><a href="" onClick={()=>{this.props.history.push('add-activity',{activity: item, isEditActivity: true})}}>Edit</a></td>
							      	<td><a href="" onClick={()=>{this.deleteActivity(item.id)}}>Delete</a></td>
							      </tr>
					    		)
					    	})
					    }
					      
					    </tbody>
					  </table>
					</div>
					<div class="col-sm-1"></div>
			</div>
		)
	}

	render(){
		return(
			<div>
				<HeaderBar props={this.props}/>
				<div class="row">
					<Sidebar />
					<div class="col-sm-10">
						{
							 this.renderActivity()		
						}
					</div>
				</div>
				<FooterBar />
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
  	auth_response: state.users.auth_response,
  	activity_list: state.posts.activity_list,
  	activity_delete_res: state.posts.activity_delete_res
  }
}
export default connect(mapStateToProps, { getAllActivity,deleteActivity })(ViewAllActivity);