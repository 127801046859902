import * as actionTypes from './actionTypes'
import axios from 'axios'
import {APP_CONSTANTS} from '../app_constants'

export function addStaffSuccess(response){
    return {
        type: actionTypes.ADD_STAFF_SUCCESS,
        payload: {
            response,
        }
    }
}

export function handleError(response){
    return {
        type: actionTypes.HAS_ERROR,
        payload: {
            response,
        }
    }
}

export function addStaff(data) {
   let header = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': data.token 
      }
    }
  return async function (dispatch) {
    axios.post(`${APP_CONSTANTS.url}/api/v1/staff`,data, header).then((response) => {
      dispatch(addStaffSuccess(response.data))
    })
    .catch(error => {
        alert(error)
      dispatch(handleError(error))
    })
  };
}


export function updateStaffSuccess(response){
    return {
        type: actionTypes.UPDATE_STAFF_SUCCESS,
        payload: {
            response,
        }
    }
}

export function updateStaff(data) {
  let header = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': data.token 
      }
    }

  return async function (dispatch) {
    axios.put(`${APP_CONSTANTS.url}/api/v1/staff/`+data.id,data, header).then((response) => {
      dispatch(updateStaffSuccess(response.data))
    })
    .catch(error => {
        alert(error)
      dispatch(handleError(error))
    })
  };
}

export function deleteStaffSuccess(response){
    return {
        type: actionTypes.DELETE_STAFF_SUCCESS,
        payload: {
            response,
        }
    }
}

export function deleteStaff(data) {
  let header = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': data.token 
      }
    }

  return async function (dispatch) {
    axios.delete(`${APP_CONSTANTS.url}/api/v1/staff/`+data.id,header).then((response) => {
      dispatch(deleteStaffSuccess(response.data))
    })
    .catch(error => {
        alert(error)
      dispatch(handleError(error))
    })
  };
}

export function getAllStaffSuccess(response){
    return {
        type: actionTypes.GET_ALL_STAFF_SUCCESS,
        payload: {
            response,
        }
    }
}

export function getAllStaff(data) {
  return async function (dispatch) {
    axios.get(`${APP_CONSTANTS.url}/api/v1/staff`).then((response) => {
      dispatch(getAllStaffSuccess(response.data))
    })
    .catch(error => {
        alert(error)
      dispatch(handleError(error))
    })
  };
}