import * as actionTypes from '../actions/actionTypes'
const initialState = {
	staff_add_res: {},
	staff_update_res: {},
	staff_delete_res: {},
	staff_list: []
}

const staff = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.ADD_STAFF_SUCCESS:
			return { ...state, staff_add_res: action.payload.response };

		case actionTypes.UPDATE_STAFF_SUCCESS:
			return { ...state, staff_update_res: action.payload.response };
			
		case actionTypes.DELETE_STAFF_SUCCESS:
			return { ...state, staff_delete_res: action.payload.response };

		case actionTypes.GET_ALL_STAFF_SUCCESS:
			return { ...state, staff_list: action.payload.response.staff_list };
			
		default:
			return state
	}
}

export default staff;