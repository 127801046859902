import React from 'react';
import HeaderBar from '../../components/header'
import FooterBar from '../../components/footer'

export default class Welcome extends React.Component {
	constructor(props) {
		super(props);
		this.state = { width: 0, height: 0 };
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	}

	render() {
		return (
			<div>
				<HeaderBar width={this.state.width} />
				<div>

					<div id="demo" class="carousel slide" data-ride="carousel">
						<ul class="carousel-indicators">
							<li data-target="#demo" data-slide-to="0" class="active"></li>
							<li data-target="#demo" data-slide-to="1"></li>
							<li data-target="#demo" data-slide-to="2"></li>
							<li data-target="#demo" data-slide-to="3"></li>

						</ul>

						<div class="carousel-inner">
							<div class="carousel-item active">
								<img style={this.state.width < 640 ? { height: 300, width: '100%' } : { height: 500, width: '100%' }} src={require("../../images/scroll-1.jpeg")} alt="Los Angeles" />
								<p class="carousel-caption">Welcome on Maharana Pratap higher secondary school</p>
							</div>
							<div class="carousel-item">
								<img style={this.state.width < 640 ? { height: 300, width: '100%' } : { height: 500, width: '100%' }} src={require("../../images/scroll-0.jpeg")} alt="Chicago" />
								<p class="carousel-caption">We Prove Quality education</p>
							</div>
							<div class="carousel-item">
								<img style={this.state.width < 640 ? { height: 300, width: '100%' } : { height: 500, width: '100%' }} src={require("../../images/scroll-2.jpeg")} alt="New York" />
								<p class="carousel-caption">We belive in overall development of student</p>
							</div>

							<div class="carousel-item">
								<img style={this.state.width < 640 ? { height: 300, width: '100%' } : { height: 500, width: '100%' }} src={require("../../images/scroll-3.jpeg")} alt="New York" />
								<p class="carousel-caption">We belive in overall development of student</p>
							</div>
						</div>

						<a class="carousel-control-prev" href="#demo" data-slide="prev">
							<span class="carousel-control-prev-icon"></span>
						</a>
						<a class="carousel-control-next" href="#demo" data-slide="next">
							<span class="carousel-control-next-icon"></span>
						</a>
					</div>

					<div class='row' style={{ paddingLeft: 13, paddingRight: 13 }}>
						<div class="col-sm-1"></div>



						<div class="col-sm-10">
							<marquee behavior="scroll" direction="left" style={{ fontSize: 20, marginTop: 15, color: 'red' }}>Maharana Pratap higher secondary school, Soyat Kalan, Madhya Pradesh </marquee>
							<div style={{ marginTop: 20 }}>
								<div class="row">
									<div class="col-sm-6" style={{ textAlign: 'center' }}>
										<img class="img-fluid rounded-circle zoom" src={require("../../images/welcome2.jpeg")} alt="Los Angeles" />
									</div>
									<div class="col-sm-6" style={{ textAlign: 'center', marginTop: 10, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
										<div>
											<h4>Our Culture</h4>
										</div>
										<div>
											Culture is the name for what people are interested in, their thoughts, their models,<br />
											the books they read and the speeches they hear” :- Walter Lippmann.
										</div>
									</div>
								</div>
							</div>



							<div style={{ marginTop: 10 }}>
								<div class="row">
									<div class="col-sm-6" style={{ textAlign: 'center', marginTop: 10, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
										<div>
											<h4>Education</h4>
										</div>
										<div>
											High School provide education in an effective way which encourage our student to do deep study and go to way of success. A deep sense of commitment to provide innovative education is the foundation for such an educational endeavour.
										</div>
									</div>
									<div class="col-sm-6" style={{ textAlign: 'center' }}>
										<img class="img-fluid rounded-circle zoom" src={require("../../images/welcome3.jpeg")} alt="Los Angeles" />
									</div>
								</div>
							</div>

							<div style={{ marginTop: 20 }}>
								<div class="row">
									<div class="col-sm-6" style={{ textAlign: 'center' }}>
										<img class="img-fluid rounded-circle zoom" src={require("../../images/moto.jpeg")} alt="Los Angeles" />
									</div>
									<div class="col-sm-6" style={{ textAlign: 'center', marginTop: 10, display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
										<div>
											<h4>Our Moto</h4>
										</div>
										<div>
											"The function of education is to teach one to think intensively and to think critically. Intelligence plus character - that is the goal of true education. Martin Luther King, Jr."
										</div>
									</div>
								</div>
							</div>

						</div>
						<div class="col-sm-1"></div>
					</div>

					<div id="review" class="carousel slide" style={{ marginTop: 20 }} data-ride="carousel">
						<ul class="carousel-indicators">
							<li data-target="#review" data-slide-to="0" class="active"></li>
							<li data-target="#review" data-slide-to="1"></li>
							<li data-target="#review" data-slide-to="2"></li>
						</ul>
						<hr />
						<div style={{ textAlign: 'center', marginTop: 25 }}>
							<h3>Parents Revies</h3>
						</div>
						<div class="carousel-inner" style={{ textAlign: 'center', paddingLeft: 13, paddingRight: 13 }}>
							<div class="carousel-item active">
								<div style={{ fontWeight: '300', fontSize: 18 }}><i><q>Staff is good, nice school</q></i></div>
								<div>-Mr. Mahesh Kumar</div>
							</div>
							<div class="carousel-item">
								<div style={{ fontWeight: '300', fontSize: 18 }}><i><q>Good education enviorment,faculty is good and everything is good</q></i></div>
								<div>- Mr. Sunil Sharma</div>
								<p class="carousel-caption">We Prove Quality education</p>
							</div>
							<div class="carousel-item">
								<div style={{ fontWeight: '300', fontSize: 18 }}><i><q>I was very serious about my child education, i search many school but this school found best on my standard, Thanks!!</q></i></div>
								<div>Mr. Dipak </div>
							</div>
						</div>

						<a class="carousel-control-prev" href="#review" data-slide="prev">
							<span class="carousel-control-prev-icon"></span>
						</a>
						<a class="carousel-control-next" href="#review" data-slide="next">
							<span class="carousel-control-next-icon"></span>
						</a>
					</div>


				</div>
				<div style={{ marginTop: 20 }}>
					<FooterBar />
				</div>
			</div>
		)
	}
}