import React from 'react';
import HeaderBar from '../../components/header'
import FooterBar from '../../components/footer'
import { connect } from 'react-redux';
import { getAllActivity } from '../../actions/posts'

class Activities extends React.Component {
	constructor(props) {
		super(props);
		this.state = { width: 0, height: 0 };
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentDidMount() {
		this.props.getAllActivity()
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	}

	render() {
		return (
			<div>
				<HeaderBar
					width={this.state.width}
					backgroundColor={'black'} />
				<div style={{ marginTop: 20, marginBottom: 15 }}>
					<div class="row">
						<div class="col-sm-1"></div>
						<div class="col-sm-10">
							<div style={{ marginTop: 15 }}>
								<div class="shadow p-4 mb-4 bg-white" style={{ color: 'black', textAlign: 'center' }}><h4><b>Activities</b></h4> </div>
							</div>
							{
								this.props.activity_list && this.props.activity_list.map((item) => {
									return (
										<div id={"accordion" + item.id} style={{ marginTop: 10 }}>
											<div class="card">
												<div class="card-header">
													<a class="card-link collapsed" data-toggle="collapse" href={'#' + item.id} aria-expanded="false">
														<h5><b>{item.title} </b></h5>
													</a>
												</div>
												<div id={'#' + item.id} class="collapse show" data-parent={"#accordion" + item.id}>
													<div class="card-body">
														<img src={item.image ? item.image : require('../../images/noimage.jpg')} style={{ width: 200, height: 200 }} />
														{item.description}
													</div>
												</div>
											</div>
										</div>
									)
								})
							}

						</div>
					</div>
				</div>
				<FooterBar />
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		auth_response: state.users.auth_response,
		activity_list: state.posts.activity_list,
	}
}
export default connect(mapStateToProps, { getAllActivity })(Activities);