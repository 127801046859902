import React from 'react';
import ReactDom from 'react-dom';
import HeaderBar from '../../../components/header'
import FooterBar from '../../../components/footer'
import Sidebar from '../../../components/sidebar'
import {localStyle} from '../AddActivity//style'
import { connect } from 'react-redux';
import firebase from "firebase";
import FileUploader from "react-firebase-file-uploader";
import {addStaff,updateStaff} from '../../../actions/staff'

const closeIcon = require('../../../images/close.png')

class AddStaff extends React.Component {
	constructor(props){
		super(props);
		this.state={
			name: '',
			education: '',
			experience: '',
			post: '',
			image: ''
		}
	}	

	componentDidMount(){
		var values = this.props.location.state
		if( values && values.staff && values.isEdit){
			this.setState({
				isEdit: values.isEdit,
				name: values.staff.name,
				education: values.staff.education,
				experience: values.staff.experience,
				post: values.staff.post,
				image: values.staff.image,
				id: values.staff.id
			})
		}
	}

	handleAddStaff(){
		var data = {
			name: this.state.name,
			education: this.state.education,
			image: this.state.image,
			post: this.state.post,
			experience: this.state.experience,
			token: this.props.auth_response.token,
			id: this.state.id
		}

		if(data.name && data.education && data.experience && data.post && data.image){
			this.state.isEdit ? this.props.updateStaff(data) : this.props.addStaff(data)
		}else{
			alert('Please enter all details')
		}
	}

	componentDidUpdate(prevProps,prevState){
		if(this.props.staff_add_res != prevProps.staff_add_res){
			if(this.props.staff_add_res.statusCode ==200){
				alert(this.props.staff_add_res.message)
				this.props.history.push('/myschool/dashboard')
			}
		}
		if(this.props.staff_update_res != prevProps.staff_update_res){
			if(this.props.staff_update_res.statusCode ==200){
				alert(this.props.staff_update_res.message)
				this.props.history.push('/myschool/all-staff')
			}
		}
	}

	renderAddActivity(){
		return(

		<div class="row">
				<div class="col-sm-3"></div>
				<div class="col-sm-6" style={localStyle.loginContainer}>
				   
			<h4 style={{textAlign: 'center'}}>{this.state.isEdit ? "Update Staff": "Add Staff"}</h4>
			<div class="form-group">
			  <label htmlFor="activity">Name:</label>
			  <input 
				type="text" 
				class="form-control" 
				id="activity" 
				value = {this.state.name}
				placeholder="Enter activity name" 
				name="activity"
				onChange = {(e) => {this.setState({name: e.target.value})}}
				/>
			</div>

			<div class="form-group">
			  <label htmlFor="post">Post:</label>
			  <input 
				type="text" 
				value = {this.state.post}
				class="form-control" 
				id="post" 
				placeholder="Enter Post name" 
				name="post"
				onChange = {(e) => {this.setState({post: e.target.value})}}
				/>
			</div>

			<div class="form-group">
			  <label htmlFor="education">Education</label>
			 <input 
				type="text" 
				class="form-control" 
				value = {this.state.education}
				id="education" 
				placeholder="Enter education name" 
				name="education"
				onChange = {(e) => {this.setState({education: e.target.value})}}
				/>
			</div>

			 <div class="form-group">
			  <label htmlFor="activity">Experience</label><br/>
			  <select value = {this.state.experience} onChange = {(e) => {this.setState({experience: e.target.value})}}>
				  <option value="1">1 year</option>
				  <option value="2">2 year</option>
				  <option value="3"> 3 year</option>
				  <option value="4">4 year</option>
				  <option value="5+">More than 4 year</option>
				</select>
			</div>


			<div class="form-group">
			  <label htmlFor="image">Add Image:</label><br/>
			  {
						this.state.image ? 
						<div>
							<div>
								<img src={closeIcon}
								onClick={()=>{this.setState({image: ''})}}
								style={{height: 10, width: 10}}
								/>
							</div>

							<div style={{marginTop: 15}}>
								<img src={this.state.image}
									style={{height: 200, width: 200}}
								/>
							</div>
						</div>
					:
					 <FileUploader
						accept="image/*"
						name="avatar"
						randomizeFilename
						storageRef={firebase.storage().ref("images")}
						onUploadStart={this.handleUploadStart}
						onUploadError={this.handleUploadError}
						onUploadSuccess={this.handleUploadSuccess}
						onProgress={this.handleProgress}
					  />
					}
			</div>
			  <div style={{textAlign: 'center', marginTop: 20}}>
				<button onClick={()=>{this.handleAddStaff()}}  style={localStyle.loginButton} >{this.state.isEdit ? "Update Staff Detils": "Add Staff"}</button></div>
					</div>
				</div>
		)
	}

	handleChangeUsername = event =>
	this.setState({ username: event.target.value });
	  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
	  handleProgress = progress => this.setState({ progress });
	  handleUploadError = error => {
		this.setState({ isUploading: false });
		console.error(error);
	  };
  handleUploadSuccess = filename => {
	this.setState({ avatar: filename, progress: 100, isUploading: false });
	firebase
	  .storage()
	  .ref("images")
	  .child(filename)
	  .getDownloadURL()
	  .then(url => this.setState({ image: url }));
  };

	render(){
		return(
			<div>
				<HeaderBar props={this.props}/>
				<div class="row" style={{justifyContent: 'center'}}>
					<Sidebar />
					<div class="col-10">
						{
							this.renderAddActivity()
						}
					</div>
				</div>
				<FooterBar />
			</div>
		)
	}
}

const mapStateToProps = (state) => {
  return {
		auth_response: state.users.auth_response,
		staff_add_res: state.staff.staff_add_res,
		staff_update_res: state.staff.staff_update_res
  }
}
export default connect(mapStateToProps, {addStaff,updateStaff})(AddStaff);