import React from 'react';
import HeaderBar from '../../components/header'
import FooterBar from '../../components/footer'
import { connect } from 'react-redux';
import { signInUser } from '../../actions/users'
import { localStyle } from './style.js'
import { checkEmailValidation } from '../../components/checkEmailValidation.js'
import { checkPasswordValidation } from '../../components/checkPasswordValidation.js'

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: ''
		}
	}

	handleLogin = () => {
		var data = {
			email: this.state.email,
			password: this.state.password
		}
		var isValidEmail = checkEmailValidation(data.email)
		var isValidPassword = checkPasswordValidation(data.password)

		if (isValidEmail) {
			if (isValidPassword) {
				this.props.signInUser(data)
			} else {
				alert('Length of Password should be greater than 5')
			}
		} else {
			alert('Invalid Email')
		}

	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.auth_response != this.props.auth_response) {
			if (this.props.auth_response.statusCode == 404 || this.props.auth_response.statusCode == 400) {
				alert(this.props.auth_response.message)
			} else {
				window.location.replace("/myschool/dashboard")
			}
		}
	}

	render() {
		return (
			<div style={{ backgroundColor: '#eeeeee' }}>
				<HeaderBar backgroundColor={'black'} />
				<div style={{ marginTop: 70 }}>
					<div class="container" style={{ color: 'black' }}>
						<div class="row">
							<div class="col-sm-1"></div>
							<div class="col-sm-10">
								<div style={{ marginTop: 120 }}>
								</div>
								<div class="row">
									<div class="col-sm-3"></div>
									<div class="col-sm-6" style={localStyle.loginContainer}>

										<h4 style={{ textAlign: 'center' }}>Admin Login</h4>
										<div class="form-group">
											<label htmlFor="email">Email:</label>
											<input
												type="email"
												class="form-control"
												id="email"
												placeholder="Enter email"
												name="email"
												onChange={(e) => { this.setState({ email: e.target.value }) }}
											/>
										</div>
										<div class="form-group">
											<label htmlFor="pwd">Password:</label>
											<input
												type="password"
												class="form-control"
												id="pwd"
												placeholder="Enter password"
												name="pwd"
												onChange={(e) => { this.setState({ password: e.target.value }) }}
											/>
										</div>
										<div style={{ textAlign: 'center', marginTop: 20 }}>
											<button onClick={() => { this.handleLogin() }} style={localStyle.loginButton} >LogIn</button></div>
									</div>
									<div class="col-sm-3"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div style={{ marginTop: 140 }}>
					<FooterBar />
				</div>
			</div>
		)

	}
}

const mapStateToProps = (state) => {
	return {
		auth_response: state.users.auth_response
	}
}
export default connect(mapStateToProps, { signInUser })(Login);
