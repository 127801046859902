import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import history from '../utils/history';


import LandingPage from '../screens/landing_page';
import Welcome from '../screens/welcome'
import AboutUs from '../screens/about_us'
import Activities from '../screens/activities'
import ContactUs from '../screens/contact_us'
import AdmissionProcedure from '../screens/admission_procedure'
import Login from '../screens/login'
//Admin Section
import AdminDashboard from '../screens/admin_dashboard'
import AddActivity from '../screens/admin_dashboard/AddActivity'
import AddStaff from '../screens/admin_dashboard/AddStaff'
import ViewAllActivity from '../screens/admin_dashboard/ViewAllActivity'
import ViewAllStaff from '../screens/admin_dashboard/ViewAllStaff'
import { connect } from 'react-redux';

import Staff from '../screens/staff'
 class GlobalRoutes extends React.Component {
	render(){
	var token = this.props.auth_response.token
	return (
		<Router history={history}>
			<Switch>
				<Route exact path="/" component={LandingPage} />
				<Route exact path="/welcome" component={Welcome} />
				<Route exact path="/about-us" component={AboutUs} />
				<Route exact path="/staff-list" component={Staff} />
				<Route exact path="/activities" component={Activities} />
				<Route exact path="/contact-us" component={ContactUs} />
				<Route exact path="/admission-procedure" component={AdmissionProcedure} />
				<Route exact path="/login" component={Login} />
				{
					token ?
						<Switch>
							<Route exact path="/myschool/dashboard" component={AdminDashboard} />
							<Route exact path="/myschool/add-activity" component={AddActivity} />
							<Route exact path="/myschool/add-staff" component={AddStaff} />
							<Route exact path="/myschool/all-activity" component={ViewAllActivity} />
							<Route exact path="/myschool/all-staff" component={ViewAllStaff} />
						</Switch>
					:
					null
				}
				
				<Route path="/" component={Welcome} />
			</Switch>
		</Router>
	);
	}
}

const mapStateToProps = (state) => {
	return {
		auth_response: state.users.auth_response,
	}
  }
  export default connect(mapStateToProps, {  })(GlobalRoutes);